import { Drawer } from "@mui/material";

import { PortalMenuView } from "./PortalMenu";
import React from "react";
import { SignOutLink } from "./SignOut";
import cn from 'classnames';
import css from '../../styles/styles.module.css';
import { MainLogo } from "app/views/components/common/MainLogo";

export const LeftSideBar: React.FC = () => {

    return <Drawer
        variant='permanent'
        data-testid="LeftSideBar"
        classes={{
            paper: cn(css['left-side-bar'])
        }}
        sx={{ width: '240px' }}
        anchor='left'
    >
        <div style={{ padding: "16px 16px 0 16px" }}>
            <MainLogo />
        </div>
        <PortalMenuView />
        <div style={{ margin: "auto 0 16px 0" }}>
            <SignOutLink />
        </div>
    </Drawer >
}