import { Avatar, Link } from "@mui/material";
import { hasValue } from "app/helpers/functions/hasValue";
import React, { Fragment } from "react";
import { useProjectInfo } from "../../hooks/useProjectInfo";

export const MainLogo: React.FC = () => {
    const { logoClickLink, logoUrl } = useProjectInfo();
    const hasHomeUrl = hasValue(logoClickLink);

    const Wrapper = !hasHomeUrl ? Fragment : Link;
    return <Wrapper {...(hasHomeUrl ? { href: logoClickLink, target: "_blank" } : {})}>
        <Avatar
            sx={{ width: "auto", height: "auto", maxWidth: "80px" }}
            variant="square"
            src={logoUrl}
        />
    </Wrapper>
}